import Styled from "../style"

const Header = () => {
  return(
    <Styled.Header>
      <img 
        width={100}
        height={100}
        alt={"Logo"}
        src="/assets/images/login/wash-fun.png" />
    </Styled.Header>
  )
}

export default Header;
import React, { useEffect, useRef, useState } from "react";
import BodyStyle from "../style/style";
import CalendarDropBox from "./calendarDropBox";

interface CalendarProps {
  year: number;
  month: number;
  leftClick: () => void;
  rightClick: () => void;
}

const Calendar: React.FC<CalendarProps> = ({
  year,
  month,
  leftClick,
  rightClick,
}) => {
  const dropDownRef = useRef<HTMLUListElement | null>(null);
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropBoxItemClick = (event: React.MouseEvent) => {
    // 요소를 클릭해도 CalendarDropBox가 닫히지 않도록 이벤트 전파를 막음
    event.stopPropagation();
  };

  return (
    <div style={{ width: "100vw", height: "60px" }}>
      <BodyStyle.Calendar ref={calendarRef}>
        <BodyStyle.LeftButton onClick={leftClick} />
        <BodyStyle.CalendarText onClick={() => setIsOpen(!isOpen)}>
          {`${year}.`}
        </BodyStyle.CalendarText>
        <BodyStyle.CalendarText onClick={() => setIsOpen(!isOpen)}>
          {month.toString().padStart(2, "0")}
        </BodyStyle.CalendarText>
        <BodyStyle.RightButton onClick={rightClick} />
        {isOpen && (
        <CalendarDropBox
          month={month}
          year={year}
          onClose={() => setIsOpen(false)}
        />
      )}
      </BodyStyle.Calendar>
    </div>
  );
};

export default Calendar;

import { create } from "zustand";
import { User } from "../../types/auth/user";
import { createJSONStorage, persist } from "zustand/middleware";

interface UserStore {
  user: User | undefined;
  isLoggedIn : boolean;
  setUser: (user: User) => void;
  setIsLoggedIn : (isLoggedInt : boolean) => void;
  deleteValue: () => void;
}

export const useUserStore = create(
  persist<UserStore>(
    (set) => ({
      user: undefined,
      isLoggedIn :false,
      setUser: (user: User) => set({user}),
      setIsLoggedIn: (isLoggedIn : boolean) => set({isLoggedIn}),
      deleteValue : () => set({user: undefined}),
    }),
    {
      name: 'user-sotre',
      storage : createJSONStorage(() => localStorage),
    }
  )
);


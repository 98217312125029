import React, { useEffect, useState } from "react";
import Styled from "../style";
import FormButton from "../formButton";
import { login, reqUser, reqeustAuthKey } from "../../../apis/auth";
import { setCookie } from "../../../utils/cookies";
import { useUserStore } from "../../../zustand/auth";
import { storeStore } from "../../../zustand/store";
import { useNavigate } from "react-router-dom";
import useCounter from "../../../hook/common/useCounter";
import colors from "../../../assets/colors/colors";

const initialTime = 180;

const LoginForm = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [authKey, setAuthKey] = useState("");
  const [authBtnStatus , setAuthBtnStatus] = useState(true);
  const [authButton, setAuthButton] = useState('인증번호 받기');

  const {setUser ,setIsLoggedIn} = useUserStore();
  const {  setStores,setStore} = storeStore();
  const { count, reset, restart } = useCounter(0, 1000);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handlePhoneEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(authBtnStatus && 'Enter' === e.key) return handleRequestAuth();
  };

  const handleAuthKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthKey(e.target.value);
  };

  const handleAuthKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(!authBtnStatus && 'Enter' === e.key) return handleLogin();
  };

  const handleRequestAuth = async () => {
    try{
      const result = await reqeustAuthKey(phone);
      if (undefined === result) {
        window.location.reload();
      }
      restart();
      const el = document.getElementById('authkey-input')
      if (null !== el) {
        window.setTimeout(() => el.focus(), 9);
      }
    }catch (error){
      console.log(error);
    }
    
  }

  const handleLogin = async () => {
    try{
      const res = await login(phone, authKey);
      if (undefined === res) {
        window.location.reload();
      }
      await setCookie('accessToken', res.token.access);
      await setCookie('refreshToken', res.token.refresh);
      const user = await reqUser();
      if (undefined === user) {
        window.location.reload();
      }

      setUser(user.user);
      if(!user.stores || !user.stores.length){
        alert("권한이 없습니다");
        setIsLoggedIn(false);
        window.location.reload();
        return;
      }

      setStores(user.stores);
      setStore(user.stores[0]);
      setIsLoggedIn(true);
    }catch (error){
      console.log(error);
      setIsLoggedIn(false);
    }
  }

  const buttonTimer = () => {
    const currentTimer = initialTime - count;
    if(count == 0) {
        RequestAuthKeyButtonReset();
    }else if(currentTimer == 0){
        RequestAuthKeyButtonReset();
        setAuthBtnStatus(true);
    }else{
        const minutes = ('00' + Math.floor(currentTimer / 60)).slice(-2);
        const seconds = ('00' + (currentTimer % 60)).slice(-2);

        setAuthButton(`(${minutes} : ${seconds})`)
        setAuthBtnStatus(false);
    } 

};

const RequestAuthKeyButtonReset = () => {
  reset();
  setAuthButton("인증번호 받기");
}
// count의 변화에 따라 timer 함수 렌더링
useEffect(buttonTimer, [count]);

  return (
    <Styled.Body>
      <Styled.InputWrapper>
        <div style={{margin: '30px'}}>
          <div>휴대폰 번호</div>
          <Styled.LoginInput id="phone-input" placeholder="01012345678" onChange={handlePhoneChange} onKeyDown={handlePhoneEnter} disabled={!authBtnStatus} style={authBtnStatus ? {}: {backgroundColor : colors.lightGray, cursor: 'not-allowed'}}/>
          <FormButton 
            label={authButton} 
            onClick={handleRequestAuth} 
            disable={authBtnStatus}/>
        </div>
        <div style={{margin: '30px'}}>
          <div>인증번호</div>
          <Styled.LoginInput id="authkey-input" placeholder="0000" onChange={handleAuthKeyChange} onKeyDown={handleAuthKeyEnter} disabled={authBtnStatus} style={!authBtnStatus ? {}: {backgroundColor : colors.lightGray, cursor: 'not-allowed'}}/>
          <FormButton 
            label={"로그인"} 
            onClick={handleLogin} 
            disable={!authBtnStatus}/>
        </div>
      </Styled.InputWrapper>
    </Styled.Body>
  )
}

export default LoginForm;
import { client } from "../api";

export const reqeustAuthKey = async (phone: string) => {
  try {
    const response = await client.put('/api/v0/user/phone', { "phone":phone });
    return response.data;
  } catch (e: any) {
    alert(e.response.data.message)
    return undefined;
  }
};


export const login = async (phone: string , code: string)=> {
  try {
    const response =  await client.post('/api/v0/user/sign', {"phone":phone, "code" :code});
    return response.data;
  } catch (e: any) {
    alert(e.response.data.message)
    return undefined;
  }
};

export const reqeustAccessToken = async (refreshToken : string) => {
  try {
    const response = await client.get(`/api/v0/user/sign`,{params:{
      token : refreshToken
    }});

    return response.data;
  } catch (e: any) {
    alert(e.response.data.message)
    return undefined;
  }
}

export const reqUser = async () => {
  try {
    const response =  await client.get('/api/v0/user');
    return response.data
  } catch (e: any) {
    alert(e.response.data.message)
    return undefined;
  }
}
import { create } from "zustand";
import { User } from "../../types/auth/user";
import { Store } from "../../types/store/store";
import { createJSONStorage, persist } from "zustand/middleware";

interface storeStates {
  store: Store | undefined;
  stores: Store[];

  setStores: (a: Store[]) => void;
  setStore: (a: Store) => void;
}

export const storeStore = create(
  persist<storeStates>(
    (set) => ({
      store : undefined,
      stores : [],
      setStores: (stores: Store[]) => set({stores}),
      setStore: (store: Store) => set({store}),
      }
    ),
    {
        name: 'store-store', // 저장소 key값
        storage: createJSONStorage(() => localStorage), // 저장소
        version: 1.0, // version 정보
    },
  )
);
import React, { useEffect, useState } from "react";
import BodyStyle from "../style/style";
import MonthGrid from "./monthGrid";
import useYearMonthStore from "../../../../zustand/calendor";

interface CalendarDropBoxProps {
  year: number;
  month : number;
  onClose: () => void;
}

const CalendarDropBox: React.FC<CalendarDropBoxProps> = ({
  year,
  month,
  onClose,
}) => {
  const {setYear,setMonth} = useYearMonthStore();
  const [boxYear, setBoxYear] = useState(year);
  const [isCurrentYear, setIsCurrentYear] = useState(true);

  useEffect(() => {
    setIsCurrentYear(boxYear === year);
  },[boxYear])
  
  const leftClick = () => {
    setBoxYear(prev => prev - 1);
  }

  const rightClick = () => {
    setBoxYear(prev => prev + 1);
  }

  const handleMonthClick = (clickMonth : number) => {
    console.log("test");
    setYear(boxYear);
    setMonth(clickMonth);
    onClose();
  }

  const handleInnerClick = (event: React.MouseEvent) => {
    console.log("test");
    // 내부 요소 클릭 시 이벤트 전파 막음
    event.stopPropagation();
  };

  
  return (
    <BodyStyle.CalendarDropBox className="active" onClick={handleInnerClick}>
      <BodyStyle.Calendar>
        <BodyStyle.LeftButton onClick={leftClick} />
        <BodyStyle.CalendarText >{`${boxYear}`}</BodyStyle.CalendarText>
        <BodyStyle.RightButton onClick={rightClick} />
      </BodyStyle.Calendar>
      <MonthGrid onMonthClick={handleMonthClick} currentMonth={month} isCurrentYear={isCurrentYear}/> {/* MonthGrid 추가 */}
    </BodyStyle.CalendarDropBox>
  );
};

export default CalendarDropBox;

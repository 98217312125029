import colors from "../../../assets/colors/colors";
import Styled from "../style";

type formButtonProps = {
  label : string;
  onClick : () => void;
  disable : boolean;
}

const FormButton = ({label, onClick, disable} : formButtonProps) => {

  return (
    <Styled.FormButton style={disable ? {backgroundColor : colors.brandColor, cursor: 'pointer'}: {backgroundColor : colors.gray, cursor: 'not-allowed'}} onClick={disable ? onClick : ()=>{}}>
      <Styled.ButtonText>{label}</Styled.ButtonText>
    </Styled.FormButton>
  )
}

export default FormButton;
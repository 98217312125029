import React from "react";
import BodyStyle from "../style/style";
import colors from "../../../../assets/colors/colors";

interface MonthGridProps {
  currentMonth : number;
  isCurrentYear : boolean;
  onMonthClick: (month: number) => void;
}

const MonthGrid: React.FC<MonthGridProps> = ({ onMonthClick, currentMonth, isCurrentYear }) => {
  const months = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
    <BodyStyle.MonthGrid>
      {months.map((month) => (
        <BodyStyle.MonthButton key={month} 
          style={currentMonth === month && isCurrentYear? 
          {background : colors.red, color : colors.white} : {}} 
          onClick={() => onMonthClick(month)}>
          {month}
        </BodyStyle.MonthButton>
      ))}
    </BodyStyle.MonthGrid>
  );
};

export default MonthGrid;
import { useNavigate } from "react-router-dom";
import { removeCookie } from "../../../utils/cookies";
import Headers from "./style/style";
import StoreBar from "./store";
import { useUserStore } from "../../../zustand/auth";
import { BASE_URL } from "../../../config";

const Header = () => {
  const navigate = useNavigate();
  const { user,setIsLoggedIn } = useUserStore();

  const onClickLogOutButton = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      logout();
      alert("로그아웃 되었습니다.");
    } 

  };

  const logout = () => {
    setIsLoggedIn(false);
    removeCookie('accessToken');
    removeCookie('refreshToken');
    navigate('/');
  }

  return (
    <Headers.Header>
      <StoreBar />
      <img 
        width={100}
        height={100}
        src="/assets/images/login/wash-fun.png" />
      <Headers.Profile onClick={onClickLogOutButton}>
        <Headers.Thumnail 
          image={`${BASE_URL}/${user?.image}`}
        />
        <Headers.StoreName>{`로그아웃`}</Headers.StoreName>
      </Headers.Profile>
    </Headers.Header>
  )
  
}

export default Header;
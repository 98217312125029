import {useRef } from "react";
import { storeStore } from "../../../zustand/store";
import useDetectClose from "../../../utils/common/useDetectClose";
import DropBoxLi from "./dropBoxLi";
import { Store } from "../../../types/store/store";
import Header from "./style/style";
import { BASE_URL } from "../../../config";

const StoreBar = () => {
  const dropDownRef = useRef<HTMLUListElement | null>(null);
  const { store,stores, setStore} = storeStore();
  const [isOpen, setIsOpen] = useDetectClose(dropDownRef, false);
  
  const changeStore = (data : Store) => {
    setStore(data)
  }
  
  return (
    <Header.Store
        onClick={()=>setIsOpen(!isOpen)}
      >
        <Header.Thumnail 
          image={`${BASE_URL}/${store?.images[0]}`}/>
        <Header.StoreName>{store?.name}</Header.StoreName>
        <Header.DropDownButton />
        <Header.Menu ref={dropDownRef}
          className={isOpen ? 'active' : ''}
        >
          {stores.map((store : Store,index) => {
            return(
              <DropBoxLi key={index} store={store} onClick={() => changeStore(store)}/>
            )
          })}
        </Header.Menu>
      </Header.Store>
  );
}



export default StoreBar;
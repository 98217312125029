import { ResponsiveLine } from '@nivo/line';

interface LineChartProps {
  data : any,
  title : String,
}

const Bar = ({title ,data} : LineChartProps) => {
  return (
    <div style={{ height: '200px', width: '100vw', marginTop : "57px"}}>
      <div style={{textAlign : "center", fontSize : "27px",fontWeight : "600"}}>{title}</div>
      <ResponsiveLine
        data={data}
        margin={{ top: 5, right: 60, bottom: 9, left: 60 }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '매출(원)',
          legendOffset: -40,
          legendPosition: 'middle'
      }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '날짜',
          legendOffset: 36,
          legendPosition: 'middle'
      }}
        curve="monotoneX"
        colors={{ scheme: 'category10' }}
        pointSize={10}
        pointBorderWidth={2}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        // legends={[
        //   {
        //     anchor: 'bottom-right',
        //     direction: 'column',
        //     justify: false,
        //     translateX: 100,
        //     translateY: 0,
        //     itemsSpacing: 0,
        //     itemDirection: 'left-to-right',
        //     itemWidth: 80,
        //     itemHeight: 20,
        //     itemOpacity: 0.75,
        //     symbolSize: 12,
        //     symbolShape: 'circle',
        //     symbolBorderColor: 'rgba(0, 0, 0, .5)',
        //   },
        // ]}
      />
    </div>
    
  );
};
export default Bar;
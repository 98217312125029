import BodyStyle from "./style/style";

interface AmountBarProps {
  year : number,
  month : number,
  yearAmount : number,
  monthAmount : number,
}

const AmountBar = ({year,month,yearAmount,monthAmount} : AmountBarProps) => {
  return (
    <div style={{width : "100vw", marginTop : "10px"}}>
      <BodyStyle.AmountView>
        <BodyStyle.AmountBar>
          <BodyStyle.AmountIndex>{`${year}년 매출`}</BodyStyle.AmountIndex>
          <BodyStyle.Amount>{`${yearAmount} 원`}</BodyStyle.Amount>
        </BodyStyle.AmountBar>
        <BodyStyle.AmountBar>
          <BodyStyle.AmountIndex>{`${month}월 매출`}</BodyStyle.AmountIndex>
          <BodyStyle.Amount>{`${monthAmount} 원`}</BodyStyle.Amount>
        </BodyStyle.AmountBar>
      </BodyStyle.AmountView>
    </div>
  )
}

export default AmountBar;
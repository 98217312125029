import styled from "styled-components";


const Styled = {
  Container: styled.div`
  `,
  Header: styled.div`
    width:100vw;
    height:100px;
    text-align : center;
    background : #FFFFFF;
  `,
  Body: styled.div`
    display:flex;
    width:100vw;
    height:75vh;
    justify-content: center;
    align-items: center;
  `,
  InputWrapper: styled.div`
    width : 400px;
    height: 400px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  `,
  LoginInput: styled.input`
    width : 360px;
    height : 30px;
    padding : 10px;
    margin-Top : 10px;
    border: 1px solid #000000;
    border-radius: 10px;
  `,
  FormButton: styled.div`
    width : 360px;
    height : 30px;
    line-height: 30px;
    margin-Top : 10px;
    margin-Bottom : 10px;
    text-align : center;
    padding : 10px;
    border-radius: 10px;
    border: 0px solid #000000;
    background : #00A3FF
  `,
  ButtonText: styled.div`
    color : #ffffff;
    font-size : 22px;
    font-weight : 700;
  `
};

export default Styled;
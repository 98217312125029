import styled from "styled-components";


const Headers = {
  Container: styled.div`
  `,
  Header: styled.div`
    width:100vw;
    height:100px;
    text-align : center;
    background : #FFFFFF;
  `,
  Store: styled.div`
    display : flex;
    position : absolute;
    left : 10px;
    top: 20px;
    width : 200px;
    height : 60px;
    cursor: pointer;
  `,
  Profile : styled.div`
    display : flex;
    position : absolute;
    right : 10px;
    top : 20px;
    width : 170px;
    height : 60px;
    cursor: pointer;
  `,
  Thumnail : styled.div<{ image:string}>`
    background-image: url(${props => props.image});
    width : 50px;
    height : 50px;
    margin : 5px;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center; 
    border-radius : 40px;
  `,
  StoreName : styled.image`
    width: 100px;
    height: 30px;
    vertical-align: middle;
    padding-Top : 19px;
    font-Size : 15px;
    font-weight : bold;
  `,
  DropDownButton : styled.div`
    position : absolute;
    background-image: url('./assets/icon/arrow-down.png');
    top : 20px;
    right : 10px;
    width: 20px;
    height : 20px;
  `,
  Menu : styled.ul`
    background: #fff;
    border-radius: 8px;
    position: absolute;
    top: 60px;
    width: 200px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    padding: 10px;
    list-style-type: none;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  `
};

export default Headers;
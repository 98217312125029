import styled from "styled-components";
import { Store } from "../../../types/store/store";
import { BASE_URL } from "../../../config";

type DropBoxLiProps = {
  store : Store,
  onClick : () => void;
}

const DropBoxLi = ({store, onClick} : DropBoxLiProps) => {

  return (
    <li onClick={onClick}>
      <MenuStyed.Store >
        <MenuStyed.Thumnail 
          image={`${BASE_URL}/${store.images[0]}`}/>
        <MenuStyed.StoreName>{store.name}</MenuStyed.StoreName>
      </MenuStyed.Store>
    </li>
  )

}

const MenuStyed = {
  Store: styled.div`
    display : flex;
    width : 200px;
    height : 60px;
  `,
  Profile : styled.div`
    display : flex;
    position : absolute;
    right : 10px;
    top : 20px;
    width : 170px;
    height : 60px;
  `,
  Thumnail : styled.div<{ image:string}>`
    background-image: url(${props => props.image});
    width : 50px;
    height : 50px;
    margin : 5px;
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center; 
    border-radius : 40px;
  `,
  StoreName : styled.image`
    width: 100px;
    height: 30px;
    vertical-align: middle;
    padding-Top : 19px;
    font-Size : 15px;
    font-weight : bold;
  `,
}

export default DropBoxLi;
import { useCallback, useEffect, useRef, useState } from "react";


interface Counter {
  count: number;
  start: () => void;
  stop: () => void;
  reset: () => void;
  restart : () => void;
}

const useCounter = (initialValue: number, ms: number): Counter => {
  const [count, setCount] = useState<number>(initialValue);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  //시작
  const start = useCallback(() => {
    if (intervalRef.current !== null) {
      return;
    }
    intervalRef.current = setInterval(() => {
      setCount((c) => c + 1);
    }, ms);
  }, [ms]);

  //일시정지
  const stop = useCallback(() => {
    if (intervalRef.current === null) {
      return;
    }
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, []);

  // 초기화
  const reset = useCallback(() => {
    setCount(0);
    stop();
  }, [stop]);

  //초기화 후 재실행
  const restart = useCallback(() => {
    setCount(1);
    start();
  }, [reset, start]);

  useEffect(() => {
    return stop;
  }, [stop]);

  return { count, start, stop, reset, restart};
};

export default useCounter;
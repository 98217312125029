import axios, { isAxiosError } from 'axios';
import { getCookie, setCookie } from '../utils/cookies';
import { reqeustAccessToken } from './auth';
import { BASE_URL } from '../config';
// import { getAccessToken, getRefreshToken, removeTokens, setAccessToken, storeTokens } from '../utils/keyChain/keychain';
// import { TokenResponse } from '../type/Auth';
// import { reqeustAccessToken } from './auth/authApi';
// import Config from 'react-native-config';
// import { AxiosResponseData } from '../type/AxiosResponseData';

export const client = axios.create({
  baseURL: BASE_URL
});

client.interceptors.request.use(
    async (config) => {
      const accessToken = await getCookie('accessToken');
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  


  client.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;
      const status = error.response?.status;

      // 401 에러 처리 (토큰 만료 등)
      if ((status === 401) && !originalRequest._retry) {
        originalRequest._retry = true; // 중복 재시도를 방지하기 위해 요청 객체에 _retry 속성 추가
        try {
          // 토큰을 재발급
          const newToken = await getNewAccessToken();
          // 재발급한 토큰을 요청 헤더에 포함
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          
          // 기존 요청을 다시 시도
          return client(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    },
  );
  
  

async function getNewAccessToken(): Promise<string | undefined> {
  const refreshToken = await getCookie("refreshToken");

  if (!refreshToken) {
    return undefined;
  }

  try {
    const res = await reqeustAccessToken(refreshToken);
    if (undefined === res) {
      window.location.reload();
    }
    const newAccessToken = res?.token?.access;

    if (!newAccessToken) {
      throw new Error('Failed to obtain new access token');
    }

    await setCookie('accessToken', res.token.access);
    await setCookie('refreshToken', res.token.refresh);
    return newAccessToken;
  } catch (error) {
    console.log("error", error);
    return undefined;
  }
}

  
  export default client;
const colors = {
  black: '#000000',
  lightGray: '#dedede',
  gray: '#666666',
  blue: '#1C215D',
  white: '#FFFFFF',
  brandColor: '#00A3FF',
  red: '#ff0000',
  card : '#D9D9D9',
  green : '#55FF00',
  placeHold : '#3F3E3D',
  background : '#E2E2E2',
  defaultBlue : '#1263CE',
  water : '#5DEA89',
  bubble : '#69D3EC',
  grayLine : '#ADB5BD'
}
export const primaryColor = '#00A3FF';

export default colors;
import { useState, useEffect, MutableRefObject } from 'react';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

function useDetectClose(
  elem: MutableRefObject<HTMLElement | null>,
  initialState: boolean
): [boolean, SetState<boolean>] {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);


  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      console.log(onClick);
      if (elem.current !== null && !elem.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [elem]);

  return [isOpen, setIsOpen];
}

export default useDetectClose;

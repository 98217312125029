import { useState, useEffect } from 'react';

export type InputData = {
  id: number;
  data: {
    prices: number[];
    record: {
      end: number;
      start: number;
      button: number;
      duration: number;
    }[];
    productIds: number[];
  };
  amount: number;
  status: string;
  paymentStatus: string;
  receiptUrl: string;
  paidAt: string;
  cancelledAt: string | null;
  failedAt: string | null;
  createdAt: string;
};

interface calculateTotalAmountByDateProps {
  dateResult : ResultData[];
  monthResult : ResultData[];
  yearAmount : number;
}

interface useCalculateTotalAmountByDateProps {
  resultDataList : ResultData[];
  resultMonthList : ResultData[];
  monthResult : number;
  yearResult : number;
}

type ResultData = {
  date: string;
  amount: number;
};

function calculateTotalAmountByDate(dataList: InputData[]): calculateTotalAmountByDateProps {
  const dateMap: { [date: string]: number } = {};
  const monthMap: { [date: string]: number } = {};
  let yearAmount = 0;

  for (const data of dataList) {
    const paidAtDate = new Date(data.createdAt).toISOString().slice(0, 10);
    const paidAtMonth = new Date(data.createdAt).toISOString().slice(0, 7) + "-01";


    if (dateMap[paidAtDate]) {
      dateMap[paidAtDate] += data.amount;
    } else {
      dateMap[paidAtDate] = data.amount;
    }

    if (monthMap[paidAtMonth]) {
      monthMap[paidAtMonth] += data.amount;
    }else {
      monthMap[paidAtMonth] = data.amount;
    }


    yearAmount += data.amount;
  }

  const dateResult: ResultData[] = [];

  for (const date in dateMap) {
    dateResult.push({ date, amount: dateMap[date] });
  }

  const monthResult: ResultData[] = [];
  for (const date in monthMap) {
    monthResult.push({ date, amount: monthMap[date] });
  }

  return {dateResult,monthResult,yearAmount};
}

function useCalculateTotalAmountByDate(dataList: InputData[], selectedMonth: number): useCalculateTotalAmountByDateProps {
  const [resultDataList, setResultDataList] = useState<ResultData[]>([]);
  const [resultMonthList, setResultMonthList] = useState<ResultData[]>([]);
  const [yearResult,setYearResult] = useState(0);
  const [monthResult,setMonthResult] = useState(0);

  useEffect(() => {
    

    const {dateResult,monthResult,yearAmount} = calculateTotalAmountByDate(dataList);

    //선택한 달 수출
    const filteredData = dateResult.filter(data => {
      const dataMonth = new Date(data.date).getMonth() + 1;
      return dataMonth === selectedMonth;
    });

    // 선택한 월의 모든 날짜를 확인하여 데이터가 없는 날은 amount를 0으로 설정
    const daysInMonth = new Date(new Date().getFullYear(), selectedMonth, 0).getDate();
    const resultWithZeroes: ResultData[] = [];
    const resultWithZeroeMonth: ResultData[] = [];
    setMonthResult(0);
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(new Date().getFullYear(), selectedMonth - 1, day + 1).toISOString().slice(0, 10);
      const existingData = filteredData.find(item => item.date === date);
      if (existingData) {
        resultWithZeroes.push(existingData);
        setMonthResult(prev => prev + existingData.amount);
      } else {
        resultWithZeroes.push({ date, amount: 0 });
      }
    }

    for (let month = 0; month < 12; month++) {
      const date = new Date(new Date().getFullYear(), month, 2).toISOString().slice(0, 10);
      const existingData = monthResult.find(item => item.date === date);
      if (existingData) {
        resultWithZeroeMonth.push(existingData);
        
      } else {
        resultWithZeroeMonth.push({ date, amount: 0 });
      }
    }
    setResultDataList(resultWithZeroes);
    setResultMonthList(resultWithZeroeMonth);
    setYearResult(yearAmount);
  }, [dataList, selectedMonth]);

  return {resultDataList,resultMonthList,monthResult,yearResult};
}
/**
 * ResultData[]데이터 포맷을 NivoLineData그래프에 맞도록 수정하는 함수
 * @param dataList ResultData[]형식의 데이터
 * @param start 날짜 슬라이싱 포인트(start :8 , end : 10) = 일, (start :5 , end : 7) = 월,
 * @param end 
 * @param format 날짜 형식
 * @returns 
 */
export function convertToNivoLineData(dataList: ResultData[],start : number,end :number,format:string): { x: string; y: number }[] {
  return dataList.map(item => ({ x: `${item.date.slice(start,end)} ${format}`, y: item.amount }));
}

export default useCalculateTotalAmountByDate;
